import React, { Component } from 'react';
import { withStyles, Typography, Link } from '@material-ui/core';
import style from './style';
// import { FormattedMessage, injectIntl } from 'react-intl';

import image_upload from './../../../assets/images/image_upload.svg';

class ImageUpload extends Component {
	constructor(props) {
		super(props);
		this.inputOpenFileRef = React.createRef();
	}

	validateFile = (file) => {
		const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
		if (validTypes.indexOf(file.type) === -1) {
			return false;
		}
		return true;
	}

	handleDragEnter = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	handleDragLeave = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const { isMain } = this.props;
		const files = e.dataTransfer.files[0];
		const reader = new FileReader();
		reader.onload = (avatar) => {
			const image = avatar.target.result;
			this.props.onChangeFile(image);
		};
		if (files && this.validateFile(files)) {
			if(isMain) {
				if (this.checkFileSize(files) <= 5) {
					reader.readAsDataURL(files);
				} else {
					this.props.onShowMessage(true, 'Error.Maxfilesize5', 'error');
				}
			} else {
				if (this.checkFileSize(files) <= 1) {
					reader.readAsDataURL(files);
				} else {
					this.props.onShowMessage(true, 'Error.Maxfilesize1', 'error');
				}
			}
		} else {
			this.props.onShowMessage(true, "Only image files are accepted", "error" )
		}
	};

	checkFileSize = (file) => {
		return file.size/1024/1024;
	}

	handleClickFile = (e) => {
		e.preventDefault();
		this.inputOpenFileRef.current.click();
	};

	handleChangFile = (e) => {
		const { isMain } = this.props;
		const files = e.target.files[0];
		const reader = new FileReader();
		reader.onload = (avatar) => {
			const image = avatar.target.result;
			this.props.onChangeFile(image);
		};
		if (files  && this.validateFile(files)) {
			if(isMain) {
				if (this.checkFileSize(files) <= 5) {
					reader.readAsDataURL(files);
				} else {
					this.props.onShowMessage(true, 'Dung lượng tối đa chỉ 5MB', 'error');
				}
			} else {
				if (this.checkFileSize(files) <= 5) {
					reader.readAsDataURL(files);
				} else {
					this.props.onShowMessage(true, 'Dung lượng tối đa chỉ 5MB', 'error');
				}
			}
		} else {
			this.props.onShowMessage(true, "Chỉ file hình được chấp nhận", "error" )
		}
	};

	render() {
		const { classes } = this.props;
		return (
			<div
				className={classes.imgWrapper}
				onDrop={(e) => this.handleDrop(e)}
				onDragOver={(e) => this.handleDragOver(e)}
				onDragEnter={(e) => this.handleDragEnter(e)}
				onDragLeave={(e) => this.handleDragLeave(e)}>
				<img src={image_upload} alt='upload img' />
				<input
					type='file'
					id='file'
					ref={this.inputOpenFileRef}
					style={{ display: 'none' }}
					onChange={this.handleChangFile}
				/>
				<Typography variant='subtitle1' component='p'>
					Drag and drop 
					<br/>
					<Link
						href='#'
						className={classes.btnLink}
						onClick={this.handleClickFile}>
						or click here
					</Link>
				</Typography>
			</div>
		);
	}
}

export default withStyles(style)(ImageUpload);
