import store from 'store'

import APIFunction from './../services';
// import LocalStorageService from "./../services/LocalStorageService";

// get user store
export const getUserStore = () => {
    return store.get('auth');
}

// get user info
export const queryUserInfo = (params) => {
    return APIFunction.queryUserInfo(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// update user info
export const updateUserInfo = (params) => {
    return APIFunction.updateUserInfo(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get user info
export const changeUserPassword = (params) => {
    return APIFunction.changeUserPassword(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get user store
export const removeUserStore = () => {
    return store.remove('auth');
}

// const localStorageService = LocalStorageService.getService();
// check validate
export const checkTokenValidate = (token) => {
    return APIFunction.checkTokenValidate({ token: token }).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}
// change Password
export const changePasswordUser = (token, password) => {
    return APIFunction.changePassword({ id: token, username: "clone", password: password }).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}
// change Password
export const changePasswordU = (old_password, new_password ,confirm_new_password) => {
    return APIFunction.changePasswordU({ old_password: old_password, new_password: new_password, confirm_new_password: confirm_new_password }).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}


// get user info
export const getListStatus = (params) => {
    return APIFunction.listStatus(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get report
export const dashboardReport = (params) => {
    return APIFunction.dashboardReport(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// query Post
export const queryPostList = (params) => {
    return APIFunction.queryPostList(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

// create Post
export const createPost = (params) => {
    return APIFunction.createPost(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

// view Post
export const viewPost = (params) => {
    return APIFunction.viewPost(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

// view Post
export const actionPost = (params) => {
    return APIFunction.actionPost(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

// update Post
export const updatePost = (params) => {
    return APIFunction.updatePost(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

export const deletePost = (params) => {
    return APIFunction.deletePost(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}