export const CANCEL_REQUEST_MESSAGE = 'cancel request'

export const DEFAULT_PAGE_SIZE = 20;

export const CURRENCY = 'Kč';

export const GOOGLE_MAPS = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&waypoints=';


export const convertSlugSeo = (title) => {
    let slug;
    //Đổi chữ hoa thành chữ thường
    slug = title ? title.toLowerCase() : "";
    //Đổi ký tự có dấu thành không dấu
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, "a");
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, "e");
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, "i");
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, "o");
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, "u");
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, "y");
    slug = slug.replace(/đ/gi, "d");
    //Xóa các ký tự đặt biệt
    slug = slug.replace(
      // /\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi,
      /\|\`|\~|\!|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\/|\?|\>|\<|\'|\"|\:|\;|\[|\]|_/gi, //@ .
      ""
    );
    //Đổi khoảng trắng thành ký tự gạch ngang
    slug = slug.replace(/ /gi, "-");
    //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
    //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
    slug = slug.replace(/\-\-\-\-\-/gi, "-");
    slug = slug.replace(/\-\-\-\-/gi, "-");
    slug = slug.replace(/\-\-\-/gi, "-");
    slug = slug.replace(/\-\-/gi, "-");
    //Xóa các ký tự gạch ngang ở đầu và cuối
    slug = "@" + slug + "@";
    slug = slug.replace(/\@\-|\-\@|\@/gi, "");
    return slug;
  };

export const TYPE_BANNER = [
  {
    key:'top',
    value: 'top',
    label: 'top'
  },
  {
    key:'top-mobile',
    value: 'top-mobile',
    label: 'top mobile'
  },
  {
    key:'middle',
    value: 'middle',
    label: 'middle'
  },
  {
    key:'highlights',
    value: 'highlights',
    label: 'Sản phẩm nổi bật'
  },
  {
    key:'sale-off',
    value: 'sale-off',
    label: 'Sản phẩm khuyến mãi'
  },
  {
    key:'hot',
    value: 'hot',
    label: 'Sản phẩm bán chạy'
  },
  {
    key:'new',
    value: 'new',
    label: 'Sản phẩm mới'
  },
  {
    key:'highlights-mobile',
    value: 'highlights-mobile',
    label: 'Sản phẩm nổi bật (Mobile)'
  },
  {
    key:'sale-off-mobile',
    value: 'sale-off-mobile',
    label: 'Sản phẩm khuyến mãi (Mobile)'
  },
  {
    key:'hot-mobile',
    value: 'hot-mobile',
    label: 'Sản phẩm bán chạy (Mobile)'
  },
  {
    key:'new-mobile',
    value: 'new-mobile',
    label: 'Sản phẩm mới (Mobile)'
  },
  {
    key:'bottom',
    value: 'bottom',
    label: 'Footer'
  }
]
