import { Fab } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import { Edit, Trash } from 'react-feather';
import style from './style';

class ImageUploaded extends Component {
	constructor(props) {
        super(props);
        this.inputOpenFileRef = React.createRef();
	}

	handleClickFile = (e) => {
		e.preventDefault();
		this.inputOpenFileRef.current.click();
	};

	handleRemoveImage = (e) => {
		e.preventDefault();
		this.props.onRemoveImage();
	};

	validateFile = (file) => {
		const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
		if (validTypes.indexOf(file.type) === -1) {
			return false;
		}
		return true;
	}

	handleChangFile = (e) => {
		const { isMain } = this.props;
		const files = e.target.files[0];
		const reader = new FileReader();
		reader.onload = (avatar) => {
			const image = avatar.target.result;
			this.props.onChangeFile(image);
		};
		if (files && this.validateFile(files)) {
			if(isMain) {
				if (this.checkFileSize(files) <= 5) {
					reader.readAsDataURL(files);
				} else {
					this.props.onShowMessage(true, 'Dung lượng tối đa chỉ 5MB', 'error');
				}
			} else {
				if (this.checkFileSize(files) <= 5) {
					reader.readAsDataURL(files);
				} else {
					this.props.onShowMessage(true, 'Dung lượng tối đa chỉ 5MB', 'error');
				}
			}
		} else {
			this.props.onShowMessage(true, "Chỉ file hình được chấp nhận", "error" )
		}
	};

	checkFileSize = (file) => {
		return file.size/1024/1024;
	}

	render() {
		const { classes, image } = this.props;

		return (
			<div
				className={classes.imgMain}
				ref={this.imgMainRef}>
				<img src={image} alt='upload' width={100}/>
				<input
					type='file'
					id='file'
					ref={this.inputOpenFileRef}
					style={{ display: 'none' }}
					onChange={this.handleChangFile}
				/>
				<div className={classes.btnAction}>
					<Fab
						color='primary'
						aria-label='edit'
						size='small'
						onClick={this.handleClickFile}
						className={`${classes.extendedIcon} ${classes.fabGreen}`}>
						<Edit size='14' />
					</Fab>
					<Fab
						color='primary'
						aria-label='delete'
						size='small'
						onClick={this.handleRemoveImage}
						className={`${classes.extendedIcon} ${classes.fabGreen}`}>
						<Trash size='14' />
					</Fab>
				</div>
			</div>
		);
	}
}
export default withStyles(style)(ImageUploaded);
