export default {
  queryRouteList: '/routes',
  // User
  queryUserInfo: 'GET /user/:id',
  checkUser: 'GET /auth/user/:id',
  getUserInfor: 'GET /user/:id',
  queryListUser: 'GET /user',
  updateUserInfo: 'PUT /user/:id',
  changeUserPassword: 'POST /change-password',
  logout: 'POST /auth/logout',
  login: 'POST /auth/login',
  forgetPassword: 'POST /forgot-password',
  createUser: 'POST /user',
  checkTokenVaid: '/forgot-password',
  registerNewPassword: 'PUT /forgot-password/:id',
  activeUser: 'POST /active-user',
  changePassword: 'PUT /forgot-password/:id',
  checkTokenValidate: 'GET /forgot-password',
  changePasswordU: 'POST /change-password',
  deleteUser: 'DELETE /user/:id',
  listStatus: 'GET /status',
  
  // Category
  queryCategory: 'GET /category',
  createCategory: 'POST /category',
  updateCategory: 'PUT /category/:id',
  deleteCategory: 'DELETE /category/:id',
  queryPost: 'GET /post',
  queryPostInfor: 'GET /post/:id',

  // Product
  queryProduct: 'GET /product',
  createProduct: 'POST /product',
  updateProduct: 'PUT /product/:id',
  deleteProduct: 'DELETE /product/:id',
  queryProductInfor: 'GET /product/:id',

  // Content
  queryContent: 'GET /content',
  createContent: 'POST /content',
  updateContent: 'PUT /content/:id',
  deleteContent: 'DELETE /content/:id',
  queryContentInfor: 'GET /content/:id',

  // Banner
  queryBanner: 'GET /banner',
  createBanner: 'POST /banner',
  updateBanner: 'PUT /banner/:id',
  deleteBanner: 'DELETE /banner/:id',
  queryBannerInfor: 'GET /banner/:id',

  // Pricing
  queryPricing: 'GET /pricing',
  createPricing: 'POST /pricing',
  updatePricing: 'PUT /pricing/:id',
  updatePricingDetail: 'PUT /pricing',
  deletePricing: 'DELETE /pricing/:id/:type',
  queryPricingInfor: 'GET /pricing/:id',
  queryPricingPostCodeInfor: 'GET /pricing/:id/:type',

  // Product
  queryOrder: 'GET /order',
  queryOrderInfor: 'GET /order/:id',
  updateListOrder: 'PUT /order',

  // Report
  dashboardReport: 'GET /report',

  //voucher
  getVoucher: 'GET /voucher',
  updateVoucher: 'PUT /voucher/:id',
  createVoucher: 'POST /voucher',
  deleteVoucher: 'DELETE /voucher/:id',

  //rank
  getListRank: 'GET /rank',
  updateRank: 'PUT /rank/:id',
  createRank: 'POST /rank',
  deleteRank: 'DELETE /rank/:id',

  // exchange rate
  queryExchangeRate: 'GET /exchange-rate',
  updateExchangeRate: 'PUT /exchange-rate/:id',

  //Weight
  createWeight: 'POST /weight',
  updateWeight: 'PUT /weight/:id',
  deleteWeight: 'DELETE /weight/:id',

  //Zone
  getListZone: 'GET /zone',
  createZone: 'POST /zone',
  updateZone: 'PUT /zone/:id',
  deleteZone: 'DELETE /zone/:id',
  updateListZone: 'PUT /zone',

  //get country
  getCountry: 'GET /country',

  // voucher setting
  updateVoucherSetting: 'POST /voucher-setting',
  getVoucherSetting: 'GET /voucher-setting',


  //post
  queryPostList: 'GET /post',
  deletePost: 'DELETE /post/:id',
  createPost: 'POST /post',
  updatePost: 'PUT /post/:id',

  //import product
  exportTemplateProduct: 'GET /export-template-product',
  importProduct: 'POST /product',
  exportProduct: 'GET /export-template-product'

}
