import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { listCategory } from '../../models/category';
import {
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  CircularProgress as Loading,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Drawer,
  Snackbar
} from "@material-ui/core";
import moment from 'moment';
import { spacing } from "@material-ui/system";
import {
  KeyboardDatePicker
} from '@material-ui/pickers';
import { Plus, Edit, Delete } from "react-feather";
import EditCategory from "./EditCategory";
import { deleteCategory } from "./../../models/category";
import { MySnackbarContentWrapper } from "./../../components/MySnackbarContentWrapper";
import CustomButton from './../../components/UI/Button';

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const styles = {
  root: {
    borderRadius: '0px'
  },
};

const TableCellHeader = styled(TableCell)`
padding-top: 0px !important;
padding-bottom: 2px !important;
padding-left: 2px !important;
padding-right: 2px !important;
`;


const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(82vw - ${props => props.theme.spacing(6)}px);
`;

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
export class CategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      order: "asc",
      orderBy: "name",
      data: [],
      loading: false,
      openDialog: false,
      openDialogError: false,
      error_message: null,
      saving: false,
      total_items: 0,
      page_size: 10,
      page: 0,
      name: "",
      name_vi: "",
      description: "",
      status: "",
      created_at: "",
      created_by: "",
      updated_at: "",
      updated_by: "",
      dataEdit: null,
      category2: [],
      category1_selected: "",
      category_selected: "",
      category_selected_name: "",
      isOpenMessage: false,
      sbColor: "error",
      sbMessage: "",
      actionLoading: false,
      isChildChange: false,
      loading_child: false
    };
  }

  async componentDidMount() {
    this.getDatas(true);
  }

  onRefeshData = () => {
    this.setState({ loading: true });
    this.getDatas();

  }

  getDatas = (isInit = false) => {
    let { isChildChange, order, orderBy } = this.state;
    const params = {
      sort_type: order,
      sort_name: orderBy
    };

    if(isInit) {
      this.setState({
        loading: true,
        loading_child: true,
        category1_selected: "",
        category_selected: "",
        category_selected_name: "",
        category2: [],
        dataEdit: null });
    }else{
      this.setState((prevState => (
        {
          loading: isChildChange ? false : true,
          loading_child: isChildChange,
          category1_selected: isChildChange ? prevState.category1_selected : "",
          category_selected: isChildChange ? prevState.category_selected : "",
          category_selected_name: isChildChange ? prevState.category_selected_name : "",
          category2: [],
          dataEdit: isChildChange ? prevState.dataEdit : null,
        }
      )));
    }

    listCategory(params).then((result) => {
      if (result.success) {
        let data = result.category ? result.category : [];
       if(isInit || (!isInit && !isChildChange)){
        this.setState({
          data: data,
          loading: false,
          loading_child: false
        });
       }else{
        if(isChildChange){
          this.setState({
            data: data,
          });
          this.loadCategory2(this.state.category1_selected, this.state.category_selected_name, this.state.dataEdit);
        }
       }
      }
    }).catch(function (error) {
      // that.setState({ isOpen: true, errorMessage: error.message })
    });

  }

  toggleDrawer = (open) => {
    // this.closeMenu('');
    this.setState({ isOpen: open });
  };

  // onEdit = (data) => {
  //   this.setState({ isOpen: true, dataEdit: data });
  // }

  handleDelete = (id) => {
    this.setState({ actionLoading: true });
    deleteCategory(id).then((res) => {
      if (res.success) {
        this.onRefeshData();
        this.setState({ openDialog: false, actionLoading: false });
      } else {
        this.setState({ openDialog: false, actionLoading: false });
      }
    })
  }

  loadCategory2 = (id, name, row) => {
    this.setState({
      isChildChange: false,
      category1_selected: id,
      category_selected: id,
      category_selected_name: name,
      dataEdit: row });
    if (this.state.data) {
      this.state.data.forEach(element => {
        if (element.id == id) {
          if (element.sub_category) {
            this.setState({ category2: element.sub_category })
          } else {
            this.setState({ category2: [] })
          }
        }
      });
    } else {
      this.setState({ category2: [] })
    }
    this.setState({loading_child: false});
  }

  category2Selected = (id, name, row) => {
    this.setState({
      category_selected: id,
      category_selected_name: name,
      dataEdit: row,
      isChildChange: true
    });

  }

  onRemove = (id) => {
    this.setState({ openDialog: true });
  }

  handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }
    this.setState({ isOpenMessage: false });
  };

  onShowMessage = (isOpenMessage, sbMessage, sbColor) => {
    this.setState({
      isOpenMessage: isOpenMessage,
      sbMessage: sbMessage,
      sbColor: sbColor,
    });
  };

  onClose = () => {
    this.setState({ openDialog: false, actionLoading: false });
  };

  render() {
    const {
      data,
      loading,
      category2,
      category_selected,
      category_selected_name,
      isOpenMessage,
      sbColor,
      sbMessage,
      openDialog,
      actionLoading,
      loading_child
    } = this.state;

    return (
      <Grid container >
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={3000}
          open={isOpenMessage}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={sbColor}
            message={sbMessage}
          />
        </Snackbar>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Card style={{ padding: '10px' }} >
                <Paper spacing={6} >
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <Grid container>
                        <Grid item md={5} xs={12}>
                          <TableContainer component={Paper}>
                            <Table aria-label="simple table"
                              style={{
                                marginTop: 10,
                                marginBottom: 20,
                                borderWidth: 1,
                                borderColor: "#e0e0e0",
                                borderStyle: "solid"
                              }}>
                              <TableHead>
                                <TableRow style={{ backgroundColor: '#E7E9EB' }}>
                                  <TableCell>Danh mục cha</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  loading ?
                                    <TableRow >
                                      <TableCell component="th" scope="row">
                                        <Loading />
                                      </TableCell>
                                    </TableRow>
                                    :
                                    data.map((row) => (
                                      <TableRow key={row.id} hover={true} onClick={() => { this.loadCategory2(row.id, row.name, row) }} selected={category_selected == row.id ? true : false}>
                                        <TableCell component="th" scope="row">
                                          {row.name}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                        <Grid item md={1} xs={1}></Grid>
                        <Grid item md={5} xs={12}>
                          <TableContainer component={Paper}>
                            <Table aria-label="simple table"
                              style={{
                                marginTop: 10,
                                marginBottom: 20,
                                borderWidth: 1,
                                borderColor: "#e0e0e0",
                                borderStyle: "solid"
                              }}
                            >
                              <TableHead>
                                <TableRow style={{ backgroundColor: '#E7E9EB' }}>
                                  <TableCell>Danh mục con</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  loading_child ?
                                    <TableRow >
                                      <TableCell component="th" scope="row">
                                        <Loading />
                                      </TableCell>
                                    </TableRow>
                                    :
                                    (category2 && category2.length > 0) ? category2.map((row) => (
                                      <TableRow key={row.id} hover={true} onClick={() => { this.category2Selected(row.id, row.name, row) }}  selected={category_selected == row.id ? true : false}>
                                        <TableCell component="th" scope="row">
                                          {row.name}
                                        </TableCell>
                                      </TableRow>
                                    )) :
                                      <TableRow key={0}>
                                        <TableCell component="th" scope="row">
                                          Không có danh mục con
                                    </TableCell>
                                      </TableRow>
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "left" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: 10, color: "green" }}
                        onClick={async () => {
                          this.toggleDrawer(true);
                          await this.setState({ dataEdit: null });
                        }}>
                        <Plus></Plus>
                        <span style={{ marginLeft: 5 }}>Thêm</span>
                      </Button>

                      {category_selected ?
                        <Button
                          variant="outlined"
                          // color="primary"
                          style={{ marginRight: 10, color: "yellowgreen" }}
                          onClick={async () => {
                            this.toggleDrawer(true);
                          }}>
                          <Edit></Edit>
                          <span style={{ marginLeft: 5 }}>Cập nhật</span>
                        </Button> :
                        <Button
                          variant="outlined"
                          disabled={true}
                          // color="primary"
                          style={{ marginRight: 10, color: "yellowgreen" }}
                        >
                          <Edit></Edit>
                          <span style={{ marginLeft: 5 }}>Cập nhật</span>
                        </Button>
                      }


                      {category_selected ?
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ marginRight: 10, color: "red" }}
                          onClick={async () => {
                            this.onRemove(category_selected)
                          }}>
                          <Delete></Delete>
                          <span style={{ marginLeft: 5 }}>Xoá</span>
                        </Button>
                        :
                        <Button
                          variant="outlined"
                          disabled={true}
                          color="primary"
                          style={{ marginRight: 10, color: "red" }}
                        >
                          <Delete></Delete>
                          <span style={{ marginLeft: 5 }}>Xoá</span>
                        </Button>

                      }
                      <Drawer anchor="right" open={this.state.isOpen} onClose={() => this.toggleDrawer(false)}>
                        <EditCategory
                          anchor="right"
                          open={this.state.isOpen}
                          onClose={() => this.toggleDrawer(false)}
                          onRefeshData={this.onRefeshData}
                          data={this.state.dataEdit}
                          onShowMessage={this.onShowMessage}
                        />
                      </Drawer>
                    </Grid>
                  </Grid>
                </Paper>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth='xs'
          open={openDialog}
          onClose={this.onClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>
            Xoá
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Bạn muốn xoá danh mục {category_selected_name}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton
              onClick={() => this.handleDelete(category_selected)}
              btnType='danger'
              loading={actionLoading}
              autoFocus>
              Có
						</CustomButton>
            <Button onClick={this.onClose} color='primary'>
              Không
						</Button>
          </DialogActions>
        </Dialog>
      </Grid >
    );
  }
}

CategoryList.propTypes = {
};

export default withStyles(styles, { withTheme: true })(CategoryList);