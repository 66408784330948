import React from "react";
import { withStyles } from '@material-ui/core/styles';

import { CategoryList } from "./CategoryList";

const styles = {
    root: {
        borderRadius: '0px'
    },
};

export class Category extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <CategoryList></CategoryList>
        );
    }
}

Category.propTypes = {
};

export default withStyles(styles, { withTheme: true })(Category);