import React from 'react';
import clsx from 'clsx';
import style from './style';
import { withStyles } from '@material-ui/styles';
import { Button, CircularProgress } from '@material-ui/core';

const button = (props) => {
	const { classes, loading, btnType, onClick, size, variant, customClass, fullWidth } = props;
	return (
		<Button
			className={clsx(customClass, {
                [classes.btn]: true,
				[classes.btnSuccess]: btnType === 'success',
				[classes.btnDanger]: btnType === 'danger',
				[classes.btnWarning]: btnType === 'warning',
				[classes.btnDefault]: btnType === 'default',
			})}
			onClick={onClick}
			disabled={loading}
			size={size}
			variant={variant}
			fullWidth={fullWidth}
			startIcon={
				loading && (
					<CircularProgress
						size={20}
						className={classes.btnLoading}
					/>
				)
			}
			type='submit'>
			{props.children}
		</Button>
	);
};

export default withStyles(style)(button);
