import APIFunction from './../services';

// query Category
export const listCategory = (params) => {
    return APIFunction.queryCategory(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}


// create Category
export const createCategory = (params) => {
    return APIFunction.createCategory(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// update Category
export const updateCategory = (params) => {
    return APIFunction.updateCategory(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// delete Category
export const deleteCategory = (id) => {
    return APIFunction.deleteCategory({id: id}).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}