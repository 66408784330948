import React from "react";

import async from "../components/Async";

import {
  List,
  Sliders,
  Users,
  UserPlus,
  Grid,
  Book,
  BookOpen,
  Truck,
  ShoppingBag,
  Coffee,
  Archive,
  Gift
} from "react-feather";
import Category from "../pages/category/Category";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
// const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const ChangePassword = async(() => import("../pages/auth/ChangePassword"));
const ResetPasswordSuccess = async(() => import("../pages/auth/ResetPasswordSuccess"));

const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));

const User = async(() => import("../pages/user/User"));
const Customer = async(() => import("../pages/customer/Customer"));
const Product = async(() => import("../pages/product/index"));
const Order = async(() => import("../pages/order/index"));
const Content = async(() => import("../pages/content/index"));
const Banner = async(() => import("../pages/banner/index"));
const DeliveryPricing = async(() => import("../pages/deliveryPricing/index"));
const Report = async(() => import("../pages/report/index"));

// voucher
const ListVoucher = async(() => import("../pages/voucher/ListVoucher"));

//Post
const Post = async(() => import("../pages/post/index"));

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/",
  // header: "Main",
  icon: <Sliders />,
  component: Default,
  children: null
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/change-password",
      name: "Change Password",
      component: ChangePassword
    },
    {
      path: "/auth/reset-password-success",
      name: "Reset Password Success",
      component: ResetPasswordSuccess
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

// const postCommentRoute = {
//   id: "Post/Comment",
//   path: "/post-comment",
//   icon: <List />,
//   children: null,
//   component: PostComment,
// };


const userRoute = {
  id: "Người dùng quản lý",
  path: "/user",
  icon: <UserPlus />,
  children: null,
  component: User,
};


const customerRoute = {
  id: "Khách hàng",
  path: "/customer",
  icon: <Users />,
  children: null,
  component: Customer,
};

const categoryRoute = {
  id: "Danh mục",
  path: "/category",
  icon: <Grid />,
  children: null,
  component: Category,
};

const bannerRoute = {
  id: "Ảnh bìa",
  path: "/banner",
  icon: <BookOpen />,
  children: null,
  component: Banner,
};

const productRoute = {
  id: "Sản phẩm",
  path: "/product",
  icon: <List />,
  children: null,
  component: Product,
};

const orderRoute = {
  id: "Đơn hàng",
  path: "/order",
  icon: <ShoppingBag/>,
  children: null,
  component: Order,
};

const contentRoute = {
  id: "Nội dung",
  path: "/content",
  icon: <Book />,
  children: null,
  component: Content,
};

const deliveryPricingRoute = {
  id: "Bảng giá vận chuyển",
  path: "/delivery-pricing",
  icon: <Truck />,
  children: null,
  component:  DeliveryPricing,
};

const reportRoute = {
  id: "Báo cáo",
  path: "/report",
  icon: <Coffee />,
  children: null,
  component:  Report,
};

const voucherRoutes = {
  id: "Voucher",
  path: "/voucher",
  icon: <Gift />,
  children: null,
  component:  ListVoucher,
}

const postRoutes = {
  id: "Blog",
  path: "/post",
  icon: <Archive />,
  children: null,
  component:  Post,
}

export const auth = [authRoutes];

export const dashboard = [
  dashboardsRoutes,
  categoryRoute,
  bannerRoute,
  userRoute,
  customerRoute,
  productRoute,
  contentRoute,
  deliveryPricingRoute,
  orderRoute,
  reportRoute,
  voucherRoutes,
  postRoutes
];

export default [
   dashboardsRoutes,
   userRoute,
   customerRoute,
   categoryRoute,
   bannerRoute,
   productRoute,
   contentRoute,
   deliveryPricingRoute,
   voucherRoutes,
   orderRoute,
   reportRoute,
   postRoutes
];
