import * as types from '../constants';
var pageId = null;

export default function reducer(state={ pageId }, actions) {
  switch (actions.type) {

    case types.SET_VIEW_CHATBOT:
      return {
        ...state,
        pageId: actions.pageId
      }

    default:
      return state
  }
}
