import { combineReducers } from 'redux';

import themeReducer from './themeReducers';
import breadcrumbReducer from './breadcrumbReducers';
import viewChatbotReducer from './viewChatbotReducers';


export default combineReducers({
	themeReducer,
	breadcrumbReducer,
	viewChatbotReducer
});
