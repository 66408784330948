import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import {Link, withRouter } from "react-router-dom";
import {
  Badge,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar as MuiToolbar,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import {
  Bell,
  MessageSquare,
  Search as SearchIcon
} from "react-feather";

import UserMenu from "./UserMenu";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const Toolbar = styled(MuiToolbar)`
  background-color: #FFF;
  box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
`;
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${props => props.theme.header.indicator.background};
    color: ${props => props.theme.palette.common.white};
  }
`;

const Breadcrumb = styled.div`
  background-color: ${props => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  ${props => props.theme.breakpoints.up("md")} {
    display: block;
  }
  font-weight: bold;
  color: #606673;
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

class LanguageMenu extends Component {
  state = {
    anchorMenu: null
  };

  toggleMenu = event => {
    this.setState({ anchorMenu: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorMenu: null });
  };

  render() {
    const { anchorMenu } = this.state;
    const open = Boolean(anchorMenu);

    return (
      <React.Fragment>
        <IconButton
          aria-owns={open ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={this.toggleMenu}
          // color="inherit"
        >
          <Flag src="/static/img/flags/us.png" alt="English" />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorMenu}
          open={open}
          onClose={this.closeMenu}
        >
          <MenuItem
            onClick={() => {
              this.closeMenu();
            }}
          >
            English
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.closeMenu();
            }}
          >
            French
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.closeMenu();
            }}
          >
            German
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.closeMenu();
            }}
          >
            Dutch
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

class Header extends Component {

  constructor(props) {
    super(props);
    // this.state = { breadcrumb: '' };
  }

  // componentDidMount() {
  //   this.setState({
  //     breadcrumb: this.props.breadcrumb
  //   })
  // }

  render() {
    let { onDrawerToggle, breadcrumb, pageId } = this.props;
    return (
      <React.Fragment>
        <AppBar position="sticky" elevation={0}>
          <Toolbar >
            <Grid container alignItems="center">
              <Hidden mdUp>
                <Grid item>
                  <IconButton
                    // color="inherit"
                    aria-label="Open drawer"
                    onClick={onDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
              <Grid item>
                <Breadcrumb>
                  {breadcrumb}
                </Breadcrumb>
              </Grid>
              <Grid item xs />
              <Grid item>
                {/* <IconButton 
                // color="inherit"
                >
                  <Indicator badgeContent={3}>
                    <MessageSquare />
                  </Indicator>
                </IconButton>
                <IconButton 
                // color="inherit"
                >
                  <Indicator badgeContent={7}>
                    <Bell />
                  </Indicator>
                </IconButton> */}
                {/* <LanguageMenu /> */}
                <UserMenu />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    breadcrumb: store.breadcrumbReducer.currentBreadcrumb,
    pageId: store.viewChatbotReducer.pageId
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Header));

