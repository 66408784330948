const style = (theme) => ({
	imgWrapper: {
		// width: '100%',
		// height: '400px',
		// maxHeight: '400px',
		width: '200px',
		height: '200px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		border: '1px dashed #d1d1d1',
		borderRadius: '4px',
		'& > img': {
			width: '150px',
			height: '180px',
			objectFit: 'scale-down',
			marginBottom: '10px',
		},

		'&:hover': {
			opacity: 0.8,
		},
	},
	btnLink: {
		color: '#c98765',
		textDecoration: 'underline',
	},
});

export default style;
