import { darken } from '@material-ui/core';

const style = (theme) => ({
    btn: {
        position: 'relative',
		fontWeight: 400,
		color: '#fff',
        '&:hover': {
			color: '#fff  !important',
		},
		"& *": {
			color: '#fff',
			textDecoration: 'none',
		}
    },
	btnSuccess: {
		backgroundColor: '#3e643e',
		'&:hover': {
			backgroundColor: darken('#3e643e', 0.2),
		},
	},
	btnDanger: {
		backgroundColor: '#e74c3c',
		'&:hover': {
			backgroundColor: darken('#e74c3c', 0.2),
		},
	},
	btnWarning: {
		backgroundColor: '#f39c12',
		'&:hover': {
			backgroundColor: darken('#f39c12', 0.2),
		},
	},
	btnDefault: {
		'& > * ': {
			color: '#000',
		}
	},
	btnLoading: {
		color: '#3e643e',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
});

export default style;
