import React from "react";
import { withStyles } from '@material-ui/core/styles';
import {
    Typography,
    ListItem,
    Fab as MuiFab,
    Grid,
    FormControl,
    CircularProgress,
    Button,
    Select,
    MenuItem,
} from "@material-ui/core";
import { green } from '@material-ui/core/colors';
import styled from "styled-components";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ImageUpload from './ImageUpload';
import ImageUploaded from './ImageUploaded';
import PropTypes from "prop-types";
import { createCategory, updateCategory, listCategory } from "./../../models/category";
const Fab = styled(MuiFab)`
  position: fixed;
  right: ${props => props.theme.spacing(8)}px;
  bottom: ${props => props.theme.spacing(8)}px;
`;

const Wrapper = styled.div`
//   width: 60vw;
  overflow-x: hidden;
`;
const Heading = styled(ListItem)`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.palette.common.black};
  background: ${props => props.theme.palette.common.white};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const styles = {
    root: {
        borderRadius: '0px'
    },
};

export class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorMenu: null,
            isOpen: false,
            errorMessage: '',
            classes: '',
            isSubmited: false,
            error: false,
            errorServer: false,
            success: false,
            id: "",
            name: "",
            level: 1,
            category_id1: 0,
            created_at: "",
            created_by: "",
            updated_at: "",
            updated_by: "",
            data: null,
            image: null,
            currentImage: null,
            category: null
        };
    }

    onChangeFile = (file) => {
        this.setState({ image: file });
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps && nextProps.data && nextProps.data !== prevState.data) {
            return {
                data: nextProps.data,
                id: nextProps.data.id,
                name: nextProps.data.name,
                level: nextProps.data.level,
                category_id1: nextProps.data.parent_id,
                image: nextProps.data.image,
                currentImage: nextProps.data.image,
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps && prevProps.data && prevProps.data !== this.props.data) {
            //Perform some operation here
            this.setState({
                data: prevProps.data,
                id: prevProps.data.id,
                name: prevProps.data.name,
                level: prevProps.data.level,
                category_id1: prevProps.data.parent_id,
                image: prevProps.data.image,
                currentImage: prevProps.data.image,
            });
        }
    }

    onSave = () => {
        const {
            id,
            name,
            description,
            level,
            data,
            category_id1,
            image,
            currentImage
        } = this.state;
        const that = this;
        this.setState({ isSubmited: true });
        if (data) {
            let params = {}
            if (currentImage != image) {
                params = { id: id, name: name, description: description, level: level, parent_id: category_id1, image: [{ id: 1, image }] };

            } else {
                params = { id: id, name: name, description: description, level: level, parent_id: category_id1 };
            }
            updateCategory(params).then((res) => {
                if (res.success) {
                    this.props.onRefeshData()
                    this.props.onClose()
                    this.props.onShowMessage(true, 'Cập nhật thành công', 'success');
                } else {
                    this.props.onShowMessage(true, 'Cập nhật thất bại', 'error');
                }
                this.setState({ isSubmited: false });
            })
        } else {
            createCategory({ name: name, description: description, level: level, parent_id: category_id1, image: [{ id: 1, image }] }).then((res) => {
                if (res.success) {
                    this.props.onRefeshData()
                    this.props.onClose()
                    this.props.onShowMessage(true, 'Thêm thành công', 'success');
                } else {
                    this.props.onShowMessage(true, 'Thêm thất bại', 'error');
                }
                this.setState({ isSubmited: false });
            })
        }
    }

    componentDidMount() {
        let params = {};
        listCategory(params).then((result) => {
            if (result.success) {
                let data = result.category ? result.category : [];
                this.setState({
                    category: data,
                    loading: false,
                });
            }
        }).catch(function (error) {
            // that.setState({
        })
    }

    render() {
        const {
            isSubmited,
            error,
            errorServer,
            success,
            name,
            level,
            category_id1,
            image,
            data,
            category,
            isOpen,
        } = this.state;

        return (
            <Wrapper>

                <Heading>Danh mục</Heading>
                <Grid container style={{ paddingLeft: 16, paddingRight: 16 }}>
                    <Grid item md={12} xs={12}>
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.onSave}
                            onError={() => { this.setState({ isOpen: false, errorMessage: '' }) }}
                        >
                            <Grid container >
                                <Grid item md={12} xs={12} style={{ paddingRight: 16 }} >
                                    <FormControl margin="normal" required fullWidth>
                                        <Typography>Tên: *</Typography>
                                        <TextValidator
                                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                            name="name"
                                            value={name}
                                            validators={['required']}
                                            errorMessages={['Bắt buộc']}
                                            inputProps={{
                                                maxLength: 255,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item md={12} xs={12} style={{ paddingRight: 16 }}>
                                    <Typography style={{ paddingTop: 16 }}>Cấp:</Typography>
                                    <Select
                                        style={{ marginTop: 4 }}
                                        name="level"
                                        fullWidth
                                        margin="dense"
                                        value={level}
                                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                    >
                                        <MenuItem
                                            key={'1'}
                                            value={1}
                                        >
                                            Danh mục cha
                                        </MenuItem>
                                        <MenuItem
                                            key={'2'}
                                            value={2}
                                        >
                                            Danh mục con
                                    </MenuItem>
                                    </Select>
                                </Grid>

                                <Grid item md={12} xs={12} style={{ paddingRight: 16, paddingTop: 16 }}>
                                    <Typography style={{ paddingTop: 16 }}>Danh mục gốc:</Typography>
                                    {
                                        level == 1 ?
                                            <Select
                                                style={{ marginTop: 4 }}
                                                name="category_id1"
                                                fullWidth
                                                margin="dense"
                                                value={category_id1}
                                                disabled={true}
                                            >
                                                <MenuItem
                                                    key={'0'}
                                                    value={0}
                                                >
                                                </MenuItem>
                                            </Select>
                                            :
                                            <Select
                                                style={{ marginTop: 4 }}
                                                name="category_id1"
                                                fullWidth
                                                margin="dense"
                                                value={category_id1}
                                                onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                            >
                                                <MenuItem
                                                    key={'0'}
                                                    value={0}
                                                >
                                                    Vui lòng chọn
                                                </MenuItem>
                                                {
                                                    category ? category.map((n, index) => {
                                                        return (
                                                            <MenuItem
                                                                key={n.id}
                                                                value={n.id}
                                                            >
                                                                {n.name}
                                                            </MenuItem>
                                                        );
                                                    }) : null
                                                }

                                            </Select>
                                    }
                                </Grid>
                                <Grid item md={12} xs={12} style={{ paddingRight: 16, paddingTop: 16 }}>
                                    <Grid item xs={12} sm={12} md={8}>
                                        <Typography style={{ paddingTop: 16 }}>Hình: (Size: 200x200)</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8}>
                                        {!image ? (
                                            <ImageUpload
                                                onChangeFile={(file) => this.onChangeFile(file)}
                                                onShowMessage={this.props.onShowMessage}
                                                isMain={true}
                                            />
                                        ) : (
                                            <ImageUploaded
                                                image={image}
                                                onRemoveImage={() => {
                                                    this.onChangeFile(null);
                                                }}
                                                onChangeFile={(file) =>
                                                    this.onChangeFile(file)
                                                }
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container style={{ textAlign: "right" }} >
                                <Grid item md={12} xs={12}  >
                                    <Button
                                        disabled={isSubmited}
                                        variant="contained"
                                        color="primary"
                                        style={{ marginTop: 20, marginRight: 10 }}
                                        mt={2}
                                        type="submit">
                                        {isSubmited && <CircularProgress style={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            width: 20,
                                            height: 20,
                                            marginTop: -10,
                                            marginLeft: -5,
                                        }} />}
                                        Save
                                    </Button>
                                    <Button
                                        disabled={isSubmited}
                                        variant="contained"
                                        color="default"
                                        style={{ marginTop: 20 }}
                                        mt={2}
                                        onClick={this.props.onClose}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                {/* <Grid item md={6} xs={12}  >
                                   
                                </Grid> */}
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                </Grid>
            </Wrapper >
        );
    }
}

Category.propTypes = {
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(Category);