import { darken } from '@material-ui/core';

const style = (theme) => ({
	imgMain: {
		width: '200px',
		height: '200px',
		position: 'relative',

		'& > img': {
			width: 'inherit',
			height: 'inherit',
			objectFit: 'scale-down',
		},
	},
	btnAction: {
		position: 'absolute',
		bottom: '10px',
		right: '20px',
	},
	extendedIcon: {
		marginRight: 10,
	},
	fabGreen: {
		backgroundColor: '#3e643e',
		'&:hover': {
			backgroundColor: darken('#3e643e', 0.2),
		},
	},
	contentWrapper: {
		position: 'relative',
	},
	contextMenu: {
		backgroundColor: '#fff',
		borderRadius: '4px',
		position: 'static !important',
		'& .react-contextmenu-item': {
			cursor: 'pointer',
			width: 'auto',
			overflow: 'hidden',
			fontSize: '14px',
			boxSizing: 'border-box',
			fontFamily: `Nunito Sans,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
			fontWeight: 400,
			lineHeight: 1.5,
			paddingTop: '6px',
			whiteSpace: 'nowrap',
			padding: '6px 16px',
			transition:
				'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			'&:hover': {
				textDecoration: 'none',
				backgroundColor: 'rgba(0, 0, 0, 0.08)',
			},
			'&:active': {
				outline: 'none',
			},
		},
	},
	point: {
		boxSizing: 'border-box',
		display: 'block',
		width: '48px',
		height: '48px',
		background: '#fff',
		boxShadow: '0 2px 10px -3px rgba(0,0,0,.5)',
		borderRadius: '50%',
		overflow: 'hidden',
		padding: '.5rem',
		'& > img': {
			objectFit: 'contain',
			maxWidth: '100%',
			maxHeight: '100%',
		}
	}
});

export default style;
